import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { about } from '../../portfolio'
import profilePhoto from '../../images/zac_profile_img.webp'
import pdf from '../../files/Zachary_Toliver_Resume.pdf';
import fancyPdf from '../../files/Zachary_Toliver_Resume_Fancy.pdf';
import './About.css'

const About = () => {
  const { name, role, description, resume, social } = about

  return (
    <div className='about center'>
      <div className='about_name__container'>
        {name && (
          <h1>
            Hi, I&apos;m <span className='about__name'>{name}.</span>
          </h1>
        )}
      </div>

      <img className='responsive-img' src={profilePhoto} alt="profile" />

      {role && <h2 className='about__role'>A {role}.</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        <div className='about_contact__title'>Resume:</div>
        {resume && (
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            <span type='button' className='btn btn--outline'>
              Plain
            </span>
          </a>
        )}

        {resume && (
          <a href={fancyPdf} target="_blank" rel="noopener noreferrer">
            <span type='button' className='btn btn--outline'>
              Fancy
            </span>
          </a>
        )}
      </div>
      <div className='about__contact center'>
        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
