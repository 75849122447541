import uniqid from 'uniqid'
import './EducationContainer.css'

const EducationContainer = ({ education }) => (
  <div className='project'>
    <h3>{education.degree}</h3>
    <h5 className='education__school-location'>{education.school} - {education.location}</h5>
    <ul>
        <li className='education__dates'> {education.startDate} - {education.endDate} </li>
    </ul>
    <p className='project__description'>{education.overview}</p>
    {education.skillsAcquired && (
      <ul className='project__stack'>
        {education.skillsAcquired.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}
  </div>
)

export default EducationContainer
