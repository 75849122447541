import uniqid from 'uniqid'
import { jobs } from '../../portfolio'
import ProfessionalExperienceContainer from '../ProfessionalExperienceContainer/ProfessionalExperienceContainer'
import './ProfessionalExperience.css'

const ProfessionalExperience = () => {
    if (!jobs.length) return null

  return (
    <section className='section' id='jobs'>
        <h2 className='section__title'>Professional Experience</h2>

        <div className='professional_experience_grid'>
        {jobs.map((job) => (
          <ProfessionalExperienceContainer key={uniqid()} job={job} />
        ))}
        </div>
    </section>
  )

}

export default ProfessionalExperience
