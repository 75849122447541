import './Footer.css'

const Footer = () => (
  <footer className='footer'>
      Created by Me - {new Date().getFullYear()}
      <br />
      <br />
      <a href='https://github.com/rajshekhar26/cleanfolio' className='link footer__link'>
      Template by Raj Shekhar
      </a>
  </footer>
)

export default Footer
