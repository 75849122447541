import uniqid from 'uniqid'
import { educationList } from '../../portfolio'
import EducationContainer from '../EducationContainer/EducationContainer'
import './Education.css'

const Education = () => {
    if (!educationList.length) return null

  return (
    <section className='section' id='education'>
        <h2 className='section__title'>Education</h2>

        <div className='projects__grid'>
        {educationList.map((education) => (
          <EducationContainer key={uniqid()} education={education} />
        ))}
        </div>
    </section>
  )

}

export default Education
