import uniqid from 'uniqid'
import './ProfessionalExperienceContainer.css'

const ProfessionalExperienceContainer = ({ job }) => (
  <div className='project'>
    <h3 className='company_name'>{job.company}</h3>
    <h5 className='professional__experience-location'>{job.location}</h5>
    {job.roles && job.roles.map((role) => (
      <div key={uniqid()}>
          <h3>{role.title}</h3>
          <ul>
              <li className='education__dates'> {role.startDate} - {role.endDate} </li>
          </ul>
          <p className='project__description'>{role.overview}</p>
          {role.skillsAcquired && (
          <div>
            <h5 className=''>Skills Acquired:</h5>
            <ul className='skill__stack'>
              {role.skillsAcquired.map((item) => (
                <li key={uniqid()} className='project__stack-item'>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          )}  
      </div>
    ))}

  </div>
)

export default ProfessionalExperienceContainer
