const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://zacharytoliver.com',
  title: 'ZT.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Zachary Toliver',
  role: 'Full-Stack Software Developer',
  description:
    'I’m a technology professional who expresses a passionate work ethic incorporated with outstanding technical and problemsolving skills. With over seven years of technical project and software development experience, I have garnered skills that make profound improvements in the growth and maintenance of the areas in which I have performed. As a leader I can relate well to others by forming trusting relationships and leading by example.',
  resume: 'https://zacharytoliver.com/resume',
  social: {
    linkedin: 'https://www.linkedin.com/in/zacharystoliver/',
    github: 'https://github.com/ztoliver',
  },
}

const educationList = [
  {
    degree: 'B.B.A. in Computer Information Systems',
    school: 'Texas State University',
    location: 'San Marcos, Texas',
    startDate: 'August 2010',
    endDate: 'December 2014',
    overview: 'The mission of the Department of Computer Information Systems and Quantitative Methods is to provide optimal educational opportunities to students wishing to pursue professional careers in Information Systems, Information Technology, and Decision Sciences.',
    skillsAcquired: [
      'Business Management',
      'Software Development Life Cycle', 
      'Programming Foundations', 
      'Entrepreneurship', 
      'Database Management', 
      'Project Management'
    ]
  }
]

const jobs = [
  {
    title: 'Principal Software Engineer',
    company: 'Dell Technologies',
    location: 'Round Rock, Texas',
    startDate: 'September 2018',
    endDate: 'Present',
    overview: 'Lead Pivotal organization-wide engineering and cultural transformation by working closely with various teams to adopt Agile & Extreme Programming methodologies. Give users of our products an equal voice by advocating for them using User-Centered Design within our balanced teams and products. Discover the needs of our customers and employees and frame solutions to deliver value to them quickly. Offer proposed design suggestions to processes and applications and exert latitude in determining objectives of an assignment.',
    skillsAcquired: [
      'Extreme Programming',
      'Lean Product Development',
      'Cloud-Native Applications',
      'Test-Driven Development'

    ],
    roles: [
      {
        title: 'Principal Software Engineer',
        company: 'Dell Technologies',
        location: 'Round Rock, Texas',
        startDate: 'September 2018',
        endDate: 'Present',
        overview: 'Lead Pivotal organization-wide engineering and cultural transformation by working closely with various teams to adopt Agile & Extreme Programming methodologies. Give users of our products an equal voice by advocating for them using User-Centered Design within our balanced teams and products. Discover the needs of our customers and employees and frame solutions to deliver value to them quickly. Offer proposed design suggestions to processes and applications and exert latitude in determining objectives of an assignment.',
        skillsAcquired: [
          'Extreme Programming',
          'Lean Product Development',
          'Cloud-Native Applications',
          'Test-Driven Development'  
        ],
      },
      {
        title: 'Senior Software Engineer',
        company: 'Dell Technologies',
        location: 'Round Rock, Texas',
        startDate: 'April 2016',
        endDate: 'September 2018',
        overview: 'A Dell Software Engineer is responsible for writing well designed, testable, and efficient C# code as well as integrating software components into a fully functional software system. This role also includes confering with systems analysts, engineers, stakeholders and others to design systems and to obtain information on project limitations and capabilities, performance, requirements and interfaces.',
        skillsAcquired: [
          'C# Programming',
          'Requirements Gathering and Analysis',
          'Agile Methodology',
          'HTML/CSS/JS',
          'Bootstrap',
          'KnockoutJS',
          'Elasticsearch',
          'SDLC'
        ]
      }
    ]
  },
  {
    title: 'Consultant',
    company: 'Sogeti USA',
    location: 'Austin, Texas',
    startDate: 'January 2015',
    endDate: 'April 2016',
    overview: 'A Dell Software Engineer is responsible for writing well designed, testable, and efficient C# code as well as integrating software components into a fully functional software system. This role also includes confering with systems analysts, engineers, stakeholders and others to design systems and to obtain information on project limitations and capabilities, performance, requirements and interfaces.',
    skillsAcquired: [
      'C# Programming',
      'Automated Functional and API Testing',
      'Requirements Analysis',
      'Agile Methodology',
      'JIRA',
      'HTML/CSS/JS',
      'Bootstrap',
      'Manual Testing',
      'SharePoint Development',
      'Mobile Device Testing'
    ],
    roles: [
      {
        title: 'Consultant',
        company: 'Sogeti USA',
        location: 'Austin, Texas',
        startDate: 'January 2015',
        endDate: 'April 2016',
        overview: 'A Dell Software Engineer is responsible for writing well designed, testable, and efficient C# code as well as integrating software components into a fully functional software system. This role also includes confering with systems analysts, engineers, stakeholders and others to design systems and to obtain information on project limitations and capabilities, performance, requirements and interfaces.',
        skillsAcquired: [
          'C# Programming',
          'Automated Functional and API Testing',
          'Requirements Analysis',
          'Agile Methodology',
          'JIRA',
          'HTML/CSS/JS',
          'Bootstrap',
          'Manual Testing',
          'SharePoint Development',
          'Mobile Device Testing'
        ],
        roles: [
        ]
      }
    ]
  }
]

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Project 1',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Project 2',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Project 3',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'C#',
  '.Net Core',
  'Elasticsearch',
  'TigerGraph',
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Material UI',
  'Git',
  'CI/CD',
]

const tools = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Postman', 
  'GitLab',
  'Cloud Foundry',
  'Visual Studio',
  'Octopus Deploy',
  'TeamCity',
  'Jira',
  'Postman',
  'Github',
  'Team Foundation Server',
  'Chrome DevTools',
  'SOAP UI',
  'Fiddler',
  'Kibana'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'email@zacharytoliver.com',
}

export { header, about, educationList, projects, jobs, skills, tools, contact }
